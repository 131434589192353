<template>
    <ui-data-table 
        :p-headers="headers"
        p-title="Empresas"
        p-read-link="companies.read.one"
        p-create-link="companies.create"
        p-name="companies"
    />
</template>
<script>

import {uiDataTable} from '@/components/ui'
export default {
    name: 'vwCompanyRead',
    components: {
        uiDataTable  
    },
    data(){
        return {
            headers: [{
                text: 'id',
                value: 'id'
            }, {
                text: 'Descrição',
                value: 'nome'
            }, {
                text: 'Email',
                value: 'email'
            }, {
                text: 'Telefone',
                value: 'telefone'
            }, {
                text: 'Cnpj',
                value: 'cnpj'
            }, {
                text: 'Inscrição',
                value: 'inscricao'
            }, {
                text: 'Cadastrado',
                value: 'criado_em'
            }, {
                text: 'Ações', 
                value: 'actions', 
                sortable: false
            }],
        }
    }
}
</script>